
.sharedialog{
    display: flex;
    gap: 10px;

    div{
        flex-basis: 100px;
        flex-grow: 1;
    }

    p {
        display: flex;
    }

    a {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        margin-right: 10px;

        &:link, &:visited, &:hover{
            text-decoration: none;
            color: #000;
        }

        img {
            width: 50px;
        }
    }

    h2 {
        margin-top: 0;
        font-size: 18px;
    }

    p {
        padding: 10px 0 0 0;
        font-family: Roboto, sans-serif;
        font-weight: normal;
        font-size: 12px;
    }
}

.infomessage {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1000;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 12px;
}

@keyframes animate-fade {
    0% { 
        opacity: 0;
        bottom: 0; 
    }
    100% { 
        opacity: 1;
        bottom: 10px; 
    }
}

@media only screen and (max-width: 1024px) {
    .infobox-bg {
        .infobox {
            width: 100%;            
        }
    }
}
