.toggle{
    font-size: 14px;
    color: #39444C;
    text-transform: uppercase;
    display: flex;
  flex-direction: row;
  flex-wrap: wrap;

    &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    .toggle-label {
        margin-right: 5px;
    }

    input {
        display:none;

        & + .checkmark {
            position: relative;
            display: inline-block;
            width:42px;
            height:24px;
            border-radius: 12px;
            background-color: #39444C;
            vertical-align: bottom;
            margin: 0 5px;

            &::after {
                content: '';
                display: block;
                width: 18px;
                height: 18px;
                border-radius: 100%;
                background-color: #fff;
                top: 3px;
                left: 3px;
                position: absolute;
                transition: all ease-in-out 0.25s;
            }
        }

        &:checked + .checkmark {
            &::after {
                top: 3px;
                left: 21px;
            }
        }
    }

    span{
        transition: all ease-in-out 0.25s;

        &.faded {
            opacity: 0.3;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .toggle{
        font-size: 11px;
    }
}
