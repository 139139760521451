.popup{
    .infobox {
        p {
            font-size: 16px;
        }

        .buttons{
            text-align: center;
            
            .button.large {
                padding-left: 50px;
                padding-right: 50px;
            }
        }
    }
}