.optionlist{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-right: 10px;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    color: #39444C;

    .option {
        cursor: pointer;
        position: relative;
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 8px;
        width: 48%;
        max-width: 160px;

        img {
            width: 100%;
            aspect-ratio: 143 / 124;
            object-fit: cover;
            margin-bottom: 5px;
            font-family: 'object-fit: cover;';
        }

        &.selected {
            &::after {
                position: absolute;
                content: '';
                width: 26px;
                height: 26px;
                display: block;
                top: -10px;
                right: -10px;
                background-image: url('../assets/selected icon.svg');
            }

            img {
                border: 2px solid #39444C;
            }
        }

        &.disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .optionlist {
        overflow: auto;
        justify-content: start;

        .option {
            margin-right: 12px;
            img {
                aspect-ratio: 143 / 98;
            }
        }
    }
}
