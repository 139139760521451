.hotspots {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform ease-in-out 0.25s;

    .hotspot {
        position: absolute;
        background-color: #39444C;
        border: 7px solid rgba(57, 68, 76, 0.25);
        width: 60px;
        height: 60px;
        border-radius: 100%;
        color: #FFF;
        font-size: 21px;
        font-weight: bold;
        background-clip: padding-box;
        text-align: center;
        line-height: 46px;
        cursor: pointer;
        transition: all ease-in-out 0.25s;
        transform: translate(-30px, -30px);

        &.selected {
            background-color: #F32643;
            border: 7px solid rgba(255, 255, 255, 0.25);
        }
    }
}

.menu-open .hotspots {
    transform: translateX(-184px);
}

@media only screen and (max-width: 1024px) {
    .menu-open .hotspots {
        transform: none;
    }
}