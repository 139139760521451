.configuratorMenu{
    position: absolute;
    right: -368px;
    top: 0;
    height: 100%;
    width: 368px;
    transition: all ease-in-out 0.25s;

    &.open{
        right: 0;
    }

    .icons{
        position: absolute;
        width: 124px;
        left: -124px;
        top: calc(50% + 30px);
        transform: translate(0, -50%);

        button {
            width: 100%;
            height: 97px;
            background-color: #fff;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
            border: 0;
            margin: 0;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 13px;
            color: #000;

            &:hover, &:active, &.selected {
                background-color: #DAE0E3;
            }
            
            svg{
                height: 47px;
                margin-top: 5px;
            }

            p {
                margin: 0;
                margin-top: 5px;
            }

            .number {
                color: #93A0A7;
            }
        }
    }

    .menuContent {
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
        padding: 28px;
        display: flex;
        flex-direction: column;

        .close {
            position: absolute;
            top: 40px;
            right: 28px;
            border: 0;
            background: transparent;
        }

        h2 {
            margin-top: 10px;
            text-transform: uppercase;

            &.hasback {
                margin-bottom: 10px;              
            }
        }        
      
        .back {
            text-transform: uppercase;
            margin: 0;           
            margin-bottom: 10px;         
            color: #F32643;
            cursor: pointer;
        }
    }
}
@media only screen and (max-height: 640px) {
    .configuratorMenu{
        .icons{
            position: absolute;
            right: 0;
            height: calc(100% - 70px);
            top: 70px;
            display: flex;
            transform: none;
            flex-direction: column;

            button {
                height: 16.6%;
                flex-shrink: 1;
                font-size: 11px;

                svg {
                    height: 50%;
                    max-height: calc(100% - 35px);
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .configuratorMenu{
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        width: 0;

        &.open {
            z-index: 10;
            .menuContainer {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 10px 70px;
                background-color: rgba(0, 0, 0, 0.7);

                .menuContent {
                    position: relative;
                    padding: 20px 40px;

                    .close {
                        top: 30px;
                        right: 30px;
                    }

                    h2 {
                        margin-top: 0;
                        margin-bottom: 10px;
                    }

                    .extraoptions {
                        display: flex;
                        gap: 20px;
                    }
                }
            }
        }
    
    
        .icons{
            position: absolute;
            right: 0;
            height: 100%;
            top: 0;
            display: flex;
            transform: none;
            flex-direction: column;
            width: 68px;
            left: -68px;

            button {
                height: 16.6%;
                flex-shrink: 1;
                font-size: 7px;

                svg {
                    height: 50%;
                    max-height: calc(100% - 35px);
                    margin: 0;
                }
            }
        }
    }
}


@media only screen and (max-width: 1024px) and  (orientation : portrait) {
    .configuratorMenu{
        display: none;
    }
}
