.logo {
    position: absolute;
    top: 10px;
    left: 43px;
}

.mobile-portrait{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #fff, $alpha: 0.75);
    z-index: 999;

    svg {
        width: 33vw;
        position: absolute;
        top: 40%;
        left: 33%;
        animation-name: rotate;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;

    }
}

@media only screen and (max-width: 1024px) {
    .logo {
        top: 5px;
        left: 10px;
    }
}

@media only screen and (max-width: 1024px) and  (orientation : portrait) {
    .mobile-portrait {
        display: block;
    }
}

@keyframes rotate {
    0% {
        opacity: 0;
        transform: rotate(-90deg);
    }
    15% {
        opacity: 1;
        transform: rotate(-90deg);
    }
    80% {
        opacity: 1;
        transform: rotate(0deg);
    }
    90% {
        opacity: 0;
        transform: rotate(0deg);
    }
    100% {
        opacity: 0;
        transform: rotate(0deg);
    }
  }
