.popup-container-enter {
    opacity: 0;
}
.popup-container-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}
.popup-container-enter-done {
    opacity: 1;
    transition: opacity 500ms;
}
.popup-container-exit {
    opacity: 1;
}
.popup-container-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

.popup-bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    z-index: 110;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup {
        padding: 25px;
        position: relative;
        background-color: #FFF;
        color: #000;
        width: 100%;
        max-width: 520px;

        .close {
            position: absolute;
            right: 25px;
            top: 25px;
            height: 42px;          
            width: 42px;
            cursor: pointer;
            border: 0;
            background: none;

            &:hover {
                background-color:#eceff1;
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 12px;

                g{
                    path {
                        stroke: #000;
                    }
                }
            }
        }

        h2 {
            margin-top: 7px;
            font-size: 22px;
        }

        p {
            padding: 10px 0 0 0;            
            font-family: Roboto, sans-serif;
            font-weight: normal;
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .popup-bg {
        .popup {
            width: 100%;
            
            .close {
                height: 26px;
                width: 26px;
            }
        }
    }
}