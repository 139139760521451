@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedBlack.eot');
    src: local('DIN Pro Condensed Black'), local('DINPro-CondensedBlack'),
        url('/fonts/DINPro-CondensedBlack.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedBlack.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedBlack.woff') format('woff'),
        url('/fonts/DINPro-CondensedBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro-Light.eot');
    src: local('DIN Pro Light'), local('DINPro-Light'),
        url('/fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-Light.woff2') format('woff2'),
        url('/fonts/DINPro-Light.woff') format('woff'),
        url('/fonts/DINPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro.eot');
    src: local('DIN Pro'), local('DINPro'),
        url('/fonts/DINPro.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro.woff2') format('woff2'),
        url('/fonts/DINPro.woff') format('woff'),
        url('/fonts/DINPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro-BoldItalic.eot');
    src: local('DIN Pro Bold Italic'), local('DINPro-BoldItalic'),
        url('/fonts/DINPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-BoldItalic.woff2') format('woff2'),
        url('/fonts/DINPro-BoldItalic.woff') format('woff'),
        url('/fonts/DINPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro-Bold.eot');
    src: local('DIN Pro Bold'), local('DINPro-Bold'),
        url('/fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-Bold.woff2') format('woff2'),
        url('/fonts/DINPro-Bold.woff') format('woff'),
        url('/fonts/DINPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedBoldItalic.eot');
    src: local('DIN Pro Condensed Bold Italic'), local('DINPro-CondensedBoldItalic'),
        url('/fonts/DINPro-CondensedBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedBoldItalic.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedBoldItalic.woff') format('woff'),
        url('/fonts/DINPro-CondensedBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro-MediumItalic.eot');
    src: local('DIN Pro Medium Italic'), local('DINPro-MediumItalic'),
        url('/fonts/DINPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-MediumItalic.woff2') format('woff2'),
        url('/fonts/DINPro-MediumItalic.woff') format('woff'),
        url('/fonts/DINPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro-Black.eot');
    src: local('DIN Pro Black'), local('DINPro-Black'),
        url('/fonts/DINPro-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-Black.woff2') format('woff2'),
        url('/fonts/DINPro-Black.woff') format('woff'),
        url('/fonts/DINPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedMedium.eot');
    src: local('DIN Pro Condensed Medium'), local('DINPro-CondensedMedium'),
        url('/fonts/DINPro-CondensedMedium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedMedium.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedMedium.woff') format('woff'),
        url('/fonts/DINPro-CondensedMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedLight.eot');
    src: local('DIN Pro Condensed Light'), local('DINPro-CondensedLight'),
        url('/fonts/DINPro-CondensedLight.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedLight.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedLight.woff') format('woff'),
        url('/fonts/DINPro-CondensedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro-Medium.eot');
    src: local('DIN Pro Medium'), local('DINPro-Medium'),
        url('/fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-Medium.woff2') format('woff2'),
        url('/fonts/DINPro-Medium.woff') format('woff'),
        url('/fonts/DINPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro-Italic.eot');
    src: local('DIN Pro Italic'), local('DINPro-Italic'),
        url('/fonts/DINPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-Italic.woff2') format('woff2'),
        url('/fonts/DINPro-Italic.woff') format('woff'),
        url('/fonts/DINPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedLightItalic.eot');
    src: local('DIN Pro Condensed Light Italic'), local('DINPro-CondensedLightItalic'),
        url('/fonts/DINPro-CondensedLightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedLightItalic.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedLightItalic.woff') format('woff'),
        url('/fonts/DINPro-CondensedLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedMediumItalic.eot');
    src: local('DIN Pro Condensed Medium Italic'), local('DINPro-CondensedMediumItalic'),
        url('/fonts/DINPro-CondensedMediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedMediumItalic.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedMediumItalic.woff') format('woff'),
        url('/fonts/DINPro-CondensedMediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedBlackItalic.eot');
    src: local('DIN Pro Condensed Black Italic'), local('DINPro-CondensedBlackItalic'),
        url('/fonts/DINPro-CondensedBlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedBlackItalic.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedBlackItalic.woff') format('woff'),
        url('/fonts/DINPro-CondensedBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedBold.eot');
    src: local('DIN Pro Condensed Bold'), local('DINPro-CondensedBold'),
        url('/fonts/DINPro-CondensedBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedBold.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedBold.woff') format('woff'),
        url('/fonts/DINPro-CondensedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro-BlackItalic.eot');
    src: local('DIN Pro Black Italic'), local('DINPro-BlackItalic'),
        url('/fonts/DINPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-BlackItalic.woff2') format('woff2'),
        url('/fonts/DINPro-BlackItalic.woff') format('woff'),
        url('/fonts/DINPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('/fonts/DINPro-LightItalic.eot');
    src: local('DIN Pro Light Italic'), local('DINPro-LightItalic'),
        url('/fonts/DINPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-LightItalic.woff2') format('woff2'),
        url('/fonts/DINPro-LightItalic.woff') format('woff'),
        url('/fonts/DINPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedItalic.eot');
    src: local('DIN Pro Condensed Italic'), local('DINPro-CondensedItalic'),
        url('/fonts/DINPro-CondensedItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedItalic.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedItalic.woff') format('woff'),
        url('/fonts/DINPro-CondensedItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('/fonts/DINPro-CondensedRegular.eot');
    src: local('DIN Pro Condensed Regular'), local('DINPro-CondensedRegular'),
        url('/fonts/DINPro-CondensedRegular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DINPro-CondensedRegular.woff2') format('woff2'),
        url('/fonts/DINPro-CondensedRegular.woff') format('woff'),
        url('/fonts/DINPro-CondensedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "DINCondensed";
    src: url('/fonts/DINCondensedDemiBold/font.woff2') format('woff2'), url('/fonts/DINCondensedDemiBold/font.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "DINCondensed";
    src: url('/fonts/DINCondensedLight/font.woff2') format('woff2'), url('/fonts/DINCondensedLight/font.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
  




@font-face{
    font-family:Roboto;
    font-style:normal;
    font-weight:400;
    font-display:swap;
    src:url('/fonts/roboto-regular.woff2') format("woff2"),url('/fonts/roboto-regular.woff') format("woff")
}

@font-face{
    font-family:Roboto;
    font-style:normal;
    font-weight:700;
    font-display:swap;
    src:url('/fonts/roboto-bold.woff2') format("woff2"),url('/fonts/roboto-bold.woff') format("woff")
}

:root {
    --app-height: 100vh;
    --app-width: 100vw;
    -webkit-text-size-adjust:100%;
}

html {
    height: 100vh;
    height: var(--app-height);
}

body{
    margin: 0;
    padding: 0;
    height: 100vh;
    height: var(--app-height);
}

#configurator {
    height: 100vh;
    height: var(--app-height);
}

.prefa-configurator {
    position: relative;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    overflow: hidden;
    color: #201007;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    font-family: 'DINCondensed', sans-serif;
    font-weight: 900;
    
    .prefa-container {
        height: 100%;
        overflow: hidden;
        position: relative;
        margin: auto;
        max-height: 100%;
    }

    button {
        cursor: pointer;
        font-family: 'DINCondensed', sans-serif;
        font-weight: 900;
    }

    .button {
        text-transform: uppercase;
        border: none;
        text-decoration: none;

        svg {
            width: 15px;
            height: 7px;
            margin-right: 6px;
        }

        &.large {
            font-size: 18px;
            padding: 10px 10px 10px 20px;
        }

        &.edge{
            position: relative;

            >span {
                display: flex;
                position: relative;
                z-index: 2;
                align-items: baseline;
            }

            &::before, &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                display: block;
                width: 80%;
                height: 100%;
                border: 1px solid transparent;
            }

            &::before {
                transform: skew(-10deg);
                transform-origin: left bottom;
                left: 0;
            }

            &::after {
                right: 0;
            }
        }

        &.white {
            background-color: transparent;
            color: #39444C;

            &::before, &::after {
                background-color: #fff;
            }

            svg {
                color: #FF000F;
            }

            &.border{
                border: 1px solid #39444c;

                &.edge{
                    border: 0;

                    &::before, &::after {
                        border: 1px solid #39444c;
                    }
                    &::before {
                        border-right: 0;
                    }
                    &::after {
                        border-left: 0;
                    }
                }
            }
        }

        &.red {
            background-color: transparent;
            color: #FFFFFF;

            &::before, &::after {
                background-color: #FF000F;
            }
        }

        &.grey {
            background-color: transparent;
            color: #FFFFFF;
            background-color: #a9aaab;

            &.edge {
                background-color: transparent;
            }

            svg {
                color: #FF000F;
            }

            &::before, &::after {
                background-color: #a9aaab;
            }
        }
    }
}

.prefa-configurator * {
    box-sizing: border-box;
}

@media only screen and (max-width: 1024px) {
    .prefa-configurator {
        width: 100vw;
        height: 100vh;
        height: var(--app-height);

    
        .prefa-container {
            aspect-ratio: unset;
            width: 100%;
            height: 100%;
        }
        

        .button {
            &.large {
                font-size: 12px;
                padding: 6px 6px 6px 12px;
            }
        }
    }
}
