header {
    position: absolute;
    right: 50px;
    padding: 20px 16px 0 0;
    top: 0;
    transition: all ease-in-out 0.25s;
    display: flex;

    .button {
        margin-left: 20px;
    }
}

.menu-open header {
    transform: translateX(-368px);
}

@media only screen and (max-width: 1024px) {
    header {
        right: 98px;
        max-width: calc(100% - 170px);

        .button {
            margin-left: 10px;

            &.large.edge{
                padding: 4px 4px 4px 8px;
            }
        }
    }

    .menu-open header {
        transform: none;
    }
}

@media only screen and (max-width: 1024px) and  (orientation : portrait) {
    header {
        .button {
            display: none;
        }
    }
}
