.layerpicker {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: normal;

    label {
        display: block;
        margin-bottom: 5px;
    }

    input[type=radio] {
        display: none;

        &+.checkmark {
            display: inline-block;
            width: 22px;
            height: 22px;
            border-width: 1px;
            border-style: solid;
            border-color: rgba(45,49,51,0.5);
            vertical-align: text-bottom;
            margin-right: 10px;
            border-radius: 100%;
            transition: border-color ease-in-out 0.25s;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background-color: #FF000F;
                position: absolute;
                top: 3px;
                left: 3px;
                opacity: 0;
                transition: all ease-in-out 0.25s;
            }
        }

        &:checked+.checkmark{
            border-width: 2px;
            border-color: #FF000F;

            &::after {
                opacity: 1;
            }
        }
    }
}

.extraoption {
    margin: 12px 0;
}

.productPicker {
    overflow: auto;
    overflow: overlay;
    flex-grow: 1;
    margin-right: -10px;
    padding-right: 10px;

    .fade-enter{
        opacity: 0;
        transform: translate(50px);
    }
    .fade-exit{
        opacity: 1;
        transform: translate(0);
    }
    .fade-enter-active{
        opacity: 1;
        transform: translate(0);
    }
    .fade-exit-active{
        opacity: 0;
        transform: translate(-50px);
    }
    .fade-enter-active,
    .fade-exit-active{
        transition: all 250ms;
    }

    &.none {
        .fade-enter{
            transform: translate(0px);
        }
        .fade-exit-active{
            transform: translate(0px);
        }
    }

    &.prev {
        .fade-enter{
            transform: translate(-50px);
        }
        .fade-exit-active{
            transform: translate(50px);
        }
    }
}

@media only screen and (max-width: 1024px) {

    .extraoption {
        margin: 3px 0;
    }

    .configuratorMenu .menuContent {
        .header {
            display: flex;

            .back {
                order: -1;
                margin-right: 10px;
                margin-top: 1px;
            }
        }
    }
    .layerpicker{
        font-size: 12px;

        label {
            margin-bottom: 0;
        }

        input[type=radio] {
            &+.checkmark {
                width: 16px;
                height: 16px;
                margin-right: 6px;

                &::after {
                    width: 6px;
                    height: 6px;
                    top: 3px;
                    left: 3px;
                }
            }
        }
    }
}
