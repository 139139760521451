.visualization {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #eee;
    transition: all ease-in-out 0.25s;

    img {
        object-fit: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transition: all ease-in-out 0.25s;
        font-family: 'object-fit: cover;';

        &.loading {
            opacity: 0;
        }

        &.overlay {
            opacity: 0;
            animation-name: pulse;
            animation-duration: 2s;
            animation-iteration-count: 2;
        }
    }
}

.menu-open .visualization {
    transform: translateX(-184px);
}

@media only screen and (max-width: 1024px) {
    .menu-open .visualization {
        transform: none;
    }
}

@keyframes pulse {
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}
