footer {
    position: absolute;
    left:10px;
    bottom:5px;

    a, a:visited, a:hover, a:active {
        color: #fff;
        text-decoration: none;
        margin-left: 14px;
        display: inline-block;
        text-shadow: 0 0 8px #000;
    }
}